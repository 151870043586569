<template>
  <div class="client">
    <v-row>
      <v-col cols="6">
        <v-card flat>
          <v-card-title class="text-h6"> Client Details </v-card-title>
          <v-card-text>
            <v-container>
              <v-list two-line>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-briefcase-outline </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      >{{ client.name }}
                      <v-badge
                        offset-x="0"
                        offset-y="-5"
                        overlap
                        dot
                        color="green"
                        v-if="client.clientStatus === 'ACTIVE'"
                      ></v-badge>
                      <v-badge
                        offset-x="0"
                        offset-y="-5"
                        overlap
                        dot
                        color="yellow"
                        v-else-if="client.clientStatus === 'CREATED'"
                      ></v-badge>
                      <v-badge
                        offset-x="0"
                        offset-y="-5"
                        overlap
                        dot
                        color="red"
                        v-else
                      ></v-badge>
                    </v-list-item-title>
                    <v-list-item-subtitle>Business Name</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-account-outline </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      >{{ client.contactFirstName }}
                      {{ client.contactLastName }}</v-list-item-title
                    >
                    <v-list-item-subtitle>Contact Person</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-email-outline </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title
                      ><a :href="`mailto:${client.contactEmail}`">{{
                        client.contactEmail
                      }}</a></v-list-item-title
                    >
                    <v-list-item-subtitle>Contact Email</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card flat>
          <v-card-title class="text-h6"> Billing Address </v-card-title>
          <v-card-text>
            <v-container>
              <v-list two-line>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-map-marker-outline </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ client.address }} </v-list-item-title>
                    <v-list-item-subtitle>Address</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-city-variant-outline </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ client.city }}</v-list-item-title>
                    <v-list-item-subtitle>City</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-postage-stamp </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ client.zipCode }}</v-list-item-title>
                    <v-list-item-subtitle>Zip Code</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-earth </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ client.country }}</v-list-item-title>
                    <v-list-item-subtitle>Country</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-speed-dial
      v-model="fab"
      right
      bottom
      up
      absolute
      slide-y-reverse-transition
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark fab>
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-icon v-else> mdi-account-circle </v-icon>
        </v-btn>
      </template>
      <v-btn
        v-if="client && client.clientStatus !== 'LOCKED'"
        fab
        dark
        small
        color="red"
        @click="lockClient()"
      >
        <v-icon>mdi-lock</v-icon>
      </v-btn>
      <v-btn
        v-if="client && client.clientStatus === 'LOCKED'"
        fab
        dark
        small
        color="green"
        @click="unlockClient()"
      >
        <v-icon>mdi-lock-open</v-icon>
      </v-btn>
      <v-btn
        v-if="client"
        fab
        dark
        small
        color="red darken-2"
        @click="deleteClient()"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script>
export default {
  data() {
    return {
      client: {},
      fab: false,
    };
  },
  created() {
    this.loadClient();
  },
  methods: {
    async loadClient() {
      try {
        const clientResponse = await this.$http.get(
          `/clients?_filter=${this.$route.params.clientId}`
        );
        this.client = clientResponse.data[0];
      } catch (error) {
        console.log(error);
      }
    },
    async deleteClient() {
      try {
        await this.$http.delete(`/clients/${this.client._id}`);
        this.$router.push({ name: "clients" });
      } catch (error) {
        console.log(error);
      }
    },
    async lockClient() {
      try {
        await this.$http.get(`/clients/lock/${this.client._id}`);
        this.loadClient();
      } catch (error) {
        console.log(error);
      }
    },
    async unlockClient() {
      try {
        await this.$http.get(`/clients/unlock/${this.client._id}`);
        this.loadClient();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
